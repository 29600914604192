/*

Refresh Today styles
--------------------
Generic styles
Page elements
Header
Sidebar
Forms
|-Counter
|-Switch
Footer

*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');


/* Generic styles --------------------------------------------------------------------------------------------------- */

$colorPrimary         : #33649f;
$colorSecondary       : #484848;
$colorSuccess         : #0f8a28;
$colorDanger          : #c70707;
$colorWarning         : #e75e02;
$colorInfo            : #754AED;
$colorDark            : #2c2c2c;
$colorGrey1           : #f1f1f1;
$colorPrimaryLight1   : lighten($colorPrimary, 60%);
$colorPrimaryLight2   : lighten($colorPrimary, 50%);
$colorLine            : rgb(223, 228, 231);
$colorHeading         : #2c2c2c;
$colorText            : #73767A;
$colorLight           : #A9ABAD;
$colorBorder          : #dfe4e7;

body {
  font-family: 'Open Sans', sans-serif;
  user-select: auto;
  -moz-user-select: auto;
  -webkit-user-select: auto;
}

a {
  cursor: pointer !important;
}

.bg-grey {
  background-color: $colorGrey1;
}

.bg-shadow {
  background-color: $colorGrey1;
}

.bg-light {
  background-color: $colorLight;
}

.bg-primary-light-1 {
  background-color: $colorPrimaryLight1;
}

.bg-primary-light-2 {
  background-color: $colorPrimaryLight2;
}

.bg-primary a {
  color: #fff;
}

.border-dark {
  border: 1px solid $colorSecondary;
}

.offer-dark {
  background-color: $colorGrey1;
  border-top: 1px solid #ddd;
}

.limit-width {
  margin: 0 auto;
  max-width: 1024px;
}

/*.text-muted,
.listview .text-muted {
  font-size: 16px;
}*/

.bt-0 {
  border-top: none;
}

/* Page elements ---------------------------------------------------------------------------------------------------- */

.page-home .appHeader {
  /*padding-left: 300px;*/
}

/* Features table for offers page */
table.table-features {
  width: 100%;
  border-left: 1px solid $colorBorder;
  border-top: 1px solid $colorBorder;
}
table.table-features td {
  border-bottom: 1px solid $colorBorder;
  border-right: 1px solid $colorBorder;
  text-align: center;
  width: 16.5%;
}

/* Options list */

ul.optionslist {
  border-top: 1px solid $colorBorder;
  border-left: 1px solid $colorBorder;
  border-right: 1px solid $colorBorder;
  background-color: #fff;
  padding-left: 0;
  width: 100%;
}
ul.optionslist li {
  border-bottom: 1px solid $colorBorder;
  padding-bottom: 12px;
  padding-top: 12px;
  list-style-type: none;
  padding-left: 10px;
}
ul.optionslist li.selected {
  font-weight: bold;
}
ul.optionslist li span {
  font-weight: bold;
}
ul.optionslist li svg,
ul.optionslist li i {
  display: none;
}
ul.optionslist li.selected svg,
ul.optionslist li.selected i {
  display: block;
  float: left;
  margin-right: 12px;
  margin-top: 4px;
}

ul.optionslist li {
  border-bottom: 1px solid #dfe4e7;
  padding-bottom: 7px;
  padding-top: 7px;
  list-style-type: none;
  padding-left: 18px;
}

ul.optionslist li:hover{
  background-color:#dfe4e7;
  color: $colorSuccess;
}
ul.optionslist{
  border-radius: 10px;
}

.offer a td {
  color: $colorText;
}

/* Autocomplete */

ul#autocomplete {
  display: none;
}

.thumbnail-wrapper {
  border: 1px solid $colorBorder;
  width: 100px;
  height: 75px;
  overflow: hidden;
  text-align: center;
}
.thumbnail-wrapper img {
  margin-left: 50%;
  transform: translateX(-50%);
  height: 75px;
}

.thumbnail-wrapper-sm {
  border: 1px solid $colorBorder;
  //width: 100px;
  //height: 75px;
  overflow: hidden;
  text-align: center;
}

.thumbnail-wrapper-sm img {
  margin-left: 50%;
  transform: translateX(-50%);
  height: 75px !important;
  width: 100px !important;
}

.count-value .quantity {
  font-size: 12px;
}

.section-title {
  color: $colorGrey1;
}
section.bg-dark .section-title,
.section.bg-dark .section-title,
section.bg-warning .section-title,
.section.bg-warning .section-title,
section.bg-danger .section-title,
.section.bg-danger .section-title {
  color: #fff;
}

.alert {
  border-radius: 0;
}

.btn-custom {
  height: 50px;
  line-height: 50px;
  width: 50px;
}

/* Listview */

.image-listview>li a.item:after {
    background-image: none;
}
.image-listview>li a.item {
  padding-right: 0;
}

/* Instellingen: items van de rechter kant halen */
li.setting-items {
  padding-right: 10px;
}

/* Header ----------------------------------------------------------------------------------------------------------- */

/* Badges in header */
.appHeader{
  .badge{
    border: 1px solid #fff;
    min-width: 28px;
    height: 28px;
    line-height: 1em;
    margin-right:5px;
    font-size: 16px;
    padding: 0 6px;
    position: static;
    right: auto;
    //top: auto;
  }
  .badge-empty{
    top: 14px;
  }
  .badge-small {
    min-width: 16px;
    height: 16px;
    line-height: 9px !important;
    font-size: 10px;
    position: absolute;
    margin-left: -18px;
    margin-top: -16px;
  }
}

.appHeader .left .icon, .appHeader .right .icon {
  color: #fff;
  font-size: 36px;
}

.bg-success .section-title {
  color: #fff;
}

.badge-empty {
  border: 1px solid #fff;
  height: 12px !important;
  width: 12px !important;
}

.fa-arrow-left{
  margin-top: 0.3em;
  position: absolute;
}

.bg-success .fa-arrow-left {
  color: #fff;
}

.right img {
  margin-right: 10px;
}


/* Sidebar ---------------------------------------------------------------------------------------------------------- */

#nav-sidebar {
  float: left;
  top: 0;
  left: 0;
  height: 100vh;
  width: 300px;
  z-index: 1000;
}

/* Close button */
.close {
  color: #fff;
  font-weight: 500;
  margin-top: 16px;
  margin-right: 20px;
  opacity: 1;
  text-shadow: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: .5;
}


/* Color of links in dark sidebar header */
.profileBox .in strong {
  color: #fff;
}

/*  Color of nav items in sidebar */
.bg-secondary a,
.bg-secondary .image-listview>li a.item {
  color: #fff !important;
}

/* Bottom border on nav items in sidebar */
.bg-secondary .image-listview>li {
  border-bottom: 1px solid #fff;
}

/* Active nav item */
.bg-secondary .image-listview>li a.active {
  background-color: $colorHeading !important;
  color: #fff !important;
}

#sidebar-wrapper {
  background-color: $colorSecondary;
  float: left;
  height: 100vh;
  width: 300px;
}

.sidebar-desktop .profileBox {
  position: static;
}

.sidebar-desktop li a:hover {
  background-color: $colorHeading;
}

/* Forms ------------------------------------------------------------------------------------------------------------ */

/* Form labels slightly lighter */
.form-group label {
  color: $colorText;
  /*font-size: 14px;*/
}

/* Position of clear input icon in login/register */
.form-group .clear-input {
  right: 0;
}

/* Bottom border on focus inputs */
.form-group.basic .custom-select:focus,
.form-group.basic .form-control:focus {
  border-bottom-color: $colorSecondary;
  box-shadow: inset 0 -1px 0 0 $colorSecondary;
}

.form-control {
  padding-right: 0 !important;
}

.btn-border {
  border: 2px solid #fff !important;
}

/* Switch ----------------------------------------------------------------------------------------------------------- */

.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 40px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $colorDanger;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 40px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $colorSuccess;
}

input:focus + .slider {
  box-shadow: 0 0 1px $colorSuccess;
}

input:checked + .slider:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
}


/* Counter ---------------------------------------------------------------------------------------------------------- */

.counter {
  border: 1px solid $colorPrimary;
  background-color: $colorPrimary;
  height: 53px;
}
.counter div {
  text-align: center;
}
.counter > div {
  float: left;
  width: 33.33%;
}
.counter div.left {
  border-right: 1px solid $colorPrimary;
}
.counter div.right {
  border-left: 1px solid $colorPrimary;
}
.counter a {
  color: #fff;
}
.counter:after {
  display: block;
  clear: both;
  content: "";
}

.count-value {
  border-left: 1px solid $colorPrimary;
  border-right: 1px solid $colorPrimary;
}

.counter-disabled, .counter-disabled div {
  background-color: #ecf5f6;
}

input.value {
  border: none;
  border-top: 1px solid $colorPrimary;
  max-width: 100%;
  text-align: center;
}

/* Footer ----------------------------------------------------------------------------------------------------------- */

/* Color of link in bottom bar */
.bg-dark a {
  color: #fff;
  &:hover,
  &:active,
  &:focus{
    color: $colorLight;
    text-decoration: none;
  }
}

#sidebarPanel .modal-body {
  margin-bottom: 0;
}

h4 {
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 5px;
}

.text-muted {
  line-height: 1.2rem;
}

#sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /*z-index: 1000;*/
}

.profileBox, .profileBox:before {
  background: $colorSecondary;
}

.section-title {
  display: block;
}

@media only screen and (min-width: 1024px) {
  #main-wrapper, .footer {
    margin-left: 300px;
  }
  .sidebar-desktop .profileBox {
    bottom: 0;
    box-shadow: none; //0 0 6px 6px rgba(0,0,0,.1), 0 0 6px 6px rgba(0,0,0,.08);
    padding-top: 5px;
    position: absolute;
    top: auto;
  }
  .appHeader {
    margin-left: 300px;
    min-height: 67px !important;
    box-shadow: none;
  }
  #appCapsule {
    padding: 67px 0;
  }
  #page-header {
    margin-left: 300px;
  }
}

/* Catalogus - detail: border om afbeeldingen smaller maken */
#main-wrapper>.section>.wide-block>.row>.col-4 {
  max-width: 100px;
}

#main-wrapper>.section>.wide-block>.offer>.row>.col-4 {
  max-width: 100px;
}

#main-wrapper>.section>.section>.wide-block>.offer>.row>.col-4 {
  max-width: 100px;
}

/* Catalogus - updatevariant: meer ruimte onderaan pagina's */
#form1>.section>.wide-block>.form-group>.input-wrapper>.wrapper-without-image>.btn {
  margin-bottom: 10px;
}

#form1>.section>.wrapper-with-image {
  margin-bottom: 50px;
}

.max-width {
  max-width: 730px;
}

/* Content max-width 1024px */


//#main-wrapper>.text-center.pt-1 {
//  max-width: 1024px!important;
//}
//
///* Catalogus + Instellingen + Account */
//#main-wrapper>.listview>.item {
//  max-width: 1024px;
//}
//
///* Catalogus details */
//#main-wrapper>.section>.wide-block>.row {
//  max-width: 1024px;
//}
//
//#main-wrapper>.section>.wide-block>.offer {
//  max-width: 1024px;
//}
//
//#main-wrapper>.section>.section>.wide-block>.offer {
//  max-width: 1024px;
//}
//
///* Catalogus update soort */
//#form1>.section>.wide-block>.form-group {
//  max-width: 1024px;
//}
//
///* Catalogus update variant */
//#main-wrapper>.section>.wide-block>.listview {
//  max-width: 1024px;
//}
//
//#form1>.section>.wide-block>.form-group {
//  max-width: 1024px;
//}
//
//#form1>.section>.wide-block>.row {
//  max-width: 1024px;
//}
//
//#form1>.section>#image-wrapper>.imaged {
//  max-width: 600px;
//}
//
//
//
///* Certificaten */
//#main-wrapper>.text-center>.form-group {
//  max-width: 1024px;
//}
//
///* Instellingen */
///* #main-wrapper>.listview>.divider-title {
//  max-width: 1024px;
//} */
//
//#main-wrapper>.listview>.setting-items>.item {
//  max-width: 1024px;
//}
//
///* Account update + Instellingen update */
//#form1>.form-group {
//  max-width: 1024px;
//}

@media screen and (min-width: 1024px) {
  #form1>.form-group>.wrapper-without-image>.btn {
    max-width: 200px;
  }

  #form1>.form-group>.input-wrapper>.btn {
    max-width: 200px;
  }

  /* #main-wrapper>.text-center>.form-group>.input-wrapper>.btn {
    max-width: 200px;
  } */
}


/* Login + Register + Recover password + Recover pincode */
@media screen and (min-width: 1024px) {
  #appCapsule>#authentication-wrapper {
    margin: 150px auto 0 auto;
    width: 40%;
  }
}

//@media screen and (max-width: 1024px) {
//  #appCapsule>#authentication-wrapper {
//    margin: 56px auto 0 auto;
//  }
//
//  #appCapsule>#recover-wrapper {
//    margin: 56px auto 0 auto;
//  }
//
//  #appCapsule>.alert {
//    margin-bottom: -56px;
//  }
//}

/* Account page bijwerken */
#main-wrapper>.image-listview>li::after {
  left: 0px!important;
}

#main-wrapper>.listview>li .item>.in {
  cursor: pointer!important;}

#main-wrapper>.listview>.bg-grey>.item {
  cursor: default!important;
}

#main-wrapper>.listview>.bg-grey>.item>.in {
  cursor: default!important;
}

/* Hamburger FA */
.headerButton svg {
  padding: 4px 3px 3px;
  font-size: 28px;
}


#sidebarPanel>.modal-dialog>.modal-content>.modal-body>.profileBox>.close-sidebar-button>.fal {
  padding: 2px 5px 1px;
  font-size: 19px;
}

/* --- JR vanaf 0206 */

#image-wrapper,
.wrapper-with-image,
.photos-detail {
  max-width: 400px;
}

.count-value input {
  width: 100%;
}

.form-group .label {
  font-size: inherit;
}

/* Foto's details */
.photos-detail {
  height: auto;
  max-width:100%;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .photos-detail {
    max-width: 600px !important;
  }
}

//html, body, #appCapsule {
//  min-height: 100%;
//  height: 100%;
//}

/* Foto's
#userimages {
  max-width: 1024px;
} */

/* Home
#homesections {
  max-width: 1024px;
}*/
/* Catalogus - updatevariant: lichtblauwe vak even groot als witte maken
#form1>.section>.wide-block>.form-group>.input-wrapper>.counter>.count-value>.bg-primary-light-2 {
  width: 300px;
}*/

.bg-danger a, .bg-warning a, bg-success a {
  color: #fff;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  background-color: #216BC4;
  border-color: #fff;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open>.dropdown-toggle.btn-danger {
  background-color: #E20E0E;
  border-color: #fff;
}

.order-products .wide-block {
  padding-left: 0;
  padding-right: 0;
}