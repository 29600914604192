body {
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  color: $colorText;
  background: $colorBackground;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // close all selects.
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

// close scrollbars.
::-webkit-scrollbar {
  width: 0;
}


a {
  transition: 0.2s all;
  color: $colorPrimary;
  outline: 0 !important;
  &:hover,
  &:active,
  &:focus {
    outline: 0 !important;
    color: $colorPrimary;
    text-decoration: none;
  }
}
button {
  outline: 0 !important;
  &:hover,
  &:active,
  &:focus {
    outline: 0 !important;
  }
}
