.dropdown,
.dropup{
  .dropdown-menu {
    border: 1px solid transparent;
    min-width: 120px;
    padding: 8px 0;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    .dropdown-item {
      padding: 12px 16px;
      line-height: 1.2em;
      color: $colorHeading;
      font-size: $fontSize;
      display: flex;
      align-items: center;
      &:hover,
      &:active {
        background: rgba($colorLine, .5);
        color: $colorHeading;
      }
    }
    .dropdown-divider {
      border-top: 1px solid $colorLine;
    }
    .dropdown-header{
      padding: 9px 16px;
      color: $colorText;
      font-weight: $regular;
      font-size: $fontSizeCaption;
    }
    .text{
      padding: 9px 16px;
      color: $colorText;
    }
    .icon,
    ion-icon{
      margin-right: 10px;
      width: 22px;
      height: 22px;
      font-size: 22px;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      display: inline-flex;
      color: $colorHeading
    }
  }
}
.appHeader{
  .dropdown-menu{
    margin-top: -10px;
  }
}

.dropdown-toggle{
  &:after{
    border-radius: 4px;
    margin-left: 7px;
  }
}