////////////////////////////////////////////////////
// ------ C O L O R S ------------------------------
////////////////////////////////////////////////////
// Theme Colors

$colorPrimary         : #33649f;
$colorSecondary       : #484848;
$colorSuccess         : #0f8a28;
$colorDanger          : #c70707;
$colorWarning         : #e75e02;
$colorInfo            : #754AED;
$colorDark            : #2c2c2c;
$colorGrey1           : #f1f1f1;
$colorPrimaryLight1   : lighten($colorPrimary, 60%);
$colorPrimaryLight2   : lighten($colorPrimary, 50%);
$colorLine            : rgb(223, 228, 231);
$colorHeading         : #2c2c2c;
$colorText            : #73767A;
$colorLight           : #A9ABAD;
$colorBorder          : #dfe4e7;
$colorBackground      : #F9F9F9;

// Dark Mode Colors
$darkModeBackground:        #0c1624;
$darkModeContentBackground: #0F1C2F;
$darkModeColorHeading:      #FFFFFF;
$darkModeColorText:         #8195a6;
$darkModeColorLight:        #586d7f;
$darkModeColorLine:         #1B283B;

////////////////////////////////////////////////////
// ------ FONT ------------------------------
////////////////////////////////////////////////////
// Google font
@import url('https://fonts.googleapis.com/css?family=Inter:400,500,700&display=swap');

// Font Family
$fontFamily:             'Inter', sans-serif;
// Define font weight
$regular :               400; // font regular
$medium :                500; // font medium (if font does not support medium, define the semibold)
$bold :                  700; // font bold or black
// Typography
$lineHeight:             1.55rem;
$letterSpacing:          -0.015rem;
// Sizes
$fontSizeHeadingXLarge:  34px;
$fontSizeHeadingLarge:   24px;
$fontSizeHeading:        17px;
$fontSize:               15px;
$fontSizeSub:            13px;
$fontSizeCaption:        11px;

////////////////////////////////////////////////////
// ------ OTHERS -----------------------------------
////////////////////////////////////////////////////
$borderRadius:           6px;
$boxShadow :             0 3px 6px 0 rgba(0,0,0,.1), 0 1px 3px 0 rgba(0,0,0,.08);

// Safearea
$safeBottom : env(safe-area-inset-bottom);
$safeTop : env(safe-area-inset-top);