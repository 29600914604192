.panelbox {
  .modal-dialog {
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    .modal-content {
      border: 0;
      border-radius: 0;
      width: 300px;
      height: 100vh;
      overflow: auto;
      padding-top: $safeTop;
      box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20);
      .modal-header {
        border-bottom: 0;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 56px;
        box-shadow: $boxShadow;
        margin-bottom: 6px;
        .panel-close{
          color: $colorPrimary;
          ion-icon{
            font-size: 26px;
          }
        }
        .modal-title {
            color: $colorHeading
        }
      }
      .modal-body {
          padding: 10px 16px;
      }
    }
  }
  &.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

.panelbox-left {
  .modal-dialog {
    transform: translate(-100%, 0) !important;
  }
}
.panelbox-right {
  .modal-dialog {
    transform: translate(100%, 0) !important;
    left: auto;
    right: 0;
  }
}
